import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Alert,
  Space,
  InputNumber,
  Select,
} from 'antd';

import config from '../../config';

import TranslatableTextInput from '../../commons/components/TranslatableTextInput';
import ImageInput from '../../commons/components/ImageInput';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

const NotificationTypeForm = (props) => {
  const {
    onSubmit,
    serverErrors,
    buttonText,
    formObject,
  } = props;

  const { t } = useTranslation();

  const { currentUser } = React.useContext(AuthenticationContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formObject;

  const handlePreSubmit = React.useCallback(async (data) => {
    let imageId = null;
    if (data.image) {
      imageId = data.image.id;
      if (imageId === null) { // significa che è stata toccata
        try {
          const result = await axios.post(`${config.baseApiUrl}/v1/images`, {
            image: data.image,
          }, {
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`,
            },
          });
          imageId = result.data.id;
        }
        catch (e) {
          // eslint-disable-next-line no-alert
          alert('Errore server nell\'upload dell\'immagine');
        }
      }
    }

    let realData;
    if (imageId) {
      realData = {
        ...data,
        image: {
          id: imageId,
        },
      };
    }
    else {
      realData = {
        ...data,
      };
    }
    onSubmit(realData);
  }, []);

  const renderServerErrors = () => {
    if (serverErrors && serverErrors.length) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <Alert message={serverErrors.join('\n')} type="error" showIcon />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(handlePreSubmit)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {renderServerErrors()}
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="titles"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.titles')}
                  validateStatus={errors != null && errors.titles != null ? 'error' : null}
                  help={errors && errors.titles && errors.titles.message}
                >
                  <TranslatableTextInput
                    value={field.value}
                    onChange={field.onChange}
                    size="small"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="shortDescriptions"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.shortDescriptions')}
                  validateStatus={errors != null && errors.shortDescriptions != null ? 'error' : null}
                  help={errors && errors.shortDescriptions && errors.shortDescriptions.message}
                >
                  <TranslatableTextInput
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="longDescriptions"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.longDescriptions')}
                  validateStatus={errors != null && errors.longDescriptions != null ? 'error' : null}
                  help={errors && errors.longDescriptions && errors.longDescriptions.message}
                >
                  <TranslatableTextInput
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="disabled"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.disabled')}
                  validateStatus={errors != null && errors.disabled != null ? 'error' : null}
                  help={errors && errors.disabled && errors.disabled.message}
                >
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
              defaultValue={false}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.status')}
                  validateStatus={errors != null && errors.status != null ? 'error' : null}
                  help={errors && errors.status && errors.status.message}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('notificationTypes.form.status')}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <Select.Option value="warning">Warning</Select.Option>
                    <Select.Option value="alert">Alert</Select.Option>
                  </Select>
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="kilometers"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.kilometers')}
                  validateStatus={errors != null && errors.kilometers != null ? 'error' : null}
                  help={errors && errors.kilometers && errors.kilometers.message}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                    parser={parseInt}
                  />
                </Form.Item>
              )}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="months"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.months')}
                  validateStatus={errors != null && errors.months != null ? 'error' : null}
                  help={errors && errors.months && errors.months.message}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                    parser={parseInt}
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="reminderKilometers"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.reminderKilometers')}
                  validateStatus={errors != null && errors.reminderKilometers != null ? 'error' : null}
                  help={errors && errors.reminderKilometers && errors.reminderKilometers.message}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                    parser={parseInt}
                  />
                </Form.Item>
              )}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="reminderMonths"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.reminderMonths')}
                  validateStatus={errors != null && errors.reminderMonths != null ? 'error' : null}
                  help={errors && errors.reminderMonths && errors.reminderMonths.message}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                    parser={parseInt}
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('notificationTypes.form.image')}
                  validateStatus={errors != null && errors.image != null ? 'error' : null}
                  help={errors && errors.image && errors.image.message}
                >
                  <ImageInput
                    value={field.value}
                    onChange={field.onChange}
                    type="images"
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>

        <Row gutter={16} justify="end">
          <Col>
            <Button type="primary" htmlType="submit">
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.any),
  buttonText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formObject: PropTypes.any.isRequired,
};

const defaultProps = {
  serverErrors: null,
};

NotificationTypeForm.propTypes = propTypes;
NotificationTypeForm.defaultProps = defaultProps;

export default NotificationTypeForm;
