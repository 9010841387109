import React from 'react';
import PropTypes from 'prop-types';

import GeneralForm from './GeneralForm';

const RearDerailleurForm = (props) => {
  const {
    onSubmit,
    serverErrors,
    formObject,
    buttonText,
  } = props;

  return (
    <GeneralForm
      formObject={formObject}
      onSubmit={onSubmit}
      serverErrors={serverErrors}
      buttonText={buttonText}
    />
  );
};

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  formObject: PropTypes.any.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const defaultProps = {
  serverErrors: null,
};

RearDerailleurForm.propTypes = propTypes;
RearDerailleurForm.defaultProps = defaultProps;

export default RearDerailleurForm;
