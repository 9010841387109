import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import {
  Row,
  Col,
  Form,
} from 'antd';
import { useTranslation } from 'react-i18next';

import GeneralForm from './GeneralForm';

import SprocketSizesInput from '../../commons/components/SprocketSizesInput';

const CrankForm = (props) => {
  const {
    onSubmit,
    serverErrors,
    formObject,
    buttonText,
  } = props;

  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = formObject;

  return (
    <GeneralForm
      formObject={formObject}
      onSubmit={onSubmit}
      serverErrors={serverErrors}
      buttonText={buttonText}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Controller
            name="sprocketSizes"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.crankForm.sprocketSizes')}
                validateStatus={errors != null && errors.sprocketSizes != null ? 'error' : null}
                help={errors && errors.sprocketSizes && errors.sprocketSizes.message}
              >
                <SprocketSizesInput
                  value={field.value}
                  onChange={field.onChange}
                />
              </Form.Item>
            )}
            rules={{
              required: t('validations.required'),
            }}
          />
        </Col>
      </Row>
    </GeneralForm>
  );
};

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  formObject: PropTypes.any.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const defaultProps = {
  serverErrors: null,
};

CrankForm.propTypes = propTypes;
CrankForm.defaultProps = defaultProps;

export default CrankForm;
