import React from 'react';
import axios from 'axios';

import config from '../../config';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

const useDetail = (path, id) => {
  const { currentUser } = React.useContext(AuthenticationContext);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const load = React.useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const result = await axios.get(`${config.baseApiUrl}${path}/${id}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      setData(result.data);
    }
    catch (e) {
      setError(e);
    }
    setLoading(false);
  }, []);

  return {
    load,
    loading,
    data,
    error,
  };
};

export default useDetail;
