import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Alert,
  Select,
} from 'antd';

import useList from '../../api/hooks/useList';

const ComponentTypeInput = (props) => {
  const {
    value,
    onChange,
    serverPath,
  } = props;

  const fullPath = React.useMemo(() => `v2/component_types/${serverPath}`, [serverPath]);

  const realValue = React.useMemo(() => {
    if (value) {
      return value.id;
    }
    return null;
  }, [value]);

  const {
    load,
    loading,
    data,
    error,
  } = useList(fullPath);

  React.useEffect(() => {
    load(0, 1000);
  }, [serverPath]);

  const handleChange = (v) => {
    if (v) {
      onChange({ id: v });
    }
    else {
      onChange(null);
    }
  };

  const renderError = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Alert message={JSON.stringify(error)} type="error" showIcon />
      </Col>
    </Row>
  );

  if ((!data && !error) || loading) {
    return null;
  }

  if (error) {
    renderError();
  }

  return (
    <Select
      value={realValue}
      onChange={handleChange}
      style={{ width: '100%' }}
      allowClear
    >
      {data.list.map((entity) => (
        <Select.Option key={entity.id} value={entity.id}>{entity.name}</Select.Option>
      ))}
    </Select>
  );
};

const propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  serverPath: PropTypes.string.isRequired,
};

const defaultProps = {
  value: null,
};

ComponentTypeInput.propTypes = propTypes;
ComponentTypeInput.defaultProps = defaultProps;

export default ComponentTypeInput;
