const translations = {
  translation: {
    validations: {
      required: 'Campo obbligatorio',
    },
    commons: {
      genericError: 'Qualcosa è andato storto',
      actions: {
        addNew: 'Aggiungi nuovo',
        download: 'Download',
        search: 'Cerca',
        create: 'Crea',
        edit: 'Modifica',
      },
      translationLanguages: {
        en: 'Inglese',
        it: 'Italiano',
        fr: 'Francese',
        de: 'Tedesco',
        es: 'Spagnolo',
        ja: 'Giapponese',
      },
      userCapabilities: {
        FIRMWARE_MANAGER: 'Gestione firmware',
        USER_MANAGER: 'Gestione utenti',
        COMPONENT_TYPE_MANAGER: 'Gestione tipi di componenti',
        MECHANIC: 'Meccanico',
        SYSTEM_ADMIN: 'Amministratore',
        LOCAL_USER_MANAGER: 'Gestione utenti locale',
        RACING_TEAM: 'Squadra corse',
        BUSINESS_INTELLIGENCE: 'Business intelligence',
      },
    },
    components: {
      imageInput: {
        fileLabel: 'File',
        urlLabel: 'Url',
      },
      sprocketSizesInput: {
        sprocketPlaceholder: 'Inserisci i valori delle corone',
      },
    },
    menu: {
      legacyEpsFirmwaresTitle: 'Legacy EPS firmware',
      legacyEpsFirmwaresSubs: {
        interfaceTitle: 'Interfaccia',
        powerUnitTitle: 'Power Unit',
        nordicTitle: 'Nordic',
      },
      wirelessEpsFirmwaresTitle: 'Wireless EPS firmware',
      wirelessEpsFirmwaresSubs: {
        backGearTitle: 'Back gear',
        frontGearTitle: 'Front gear',
        leftCommandTitle: 'Left command',
        rightCommandTitle: 'Right command',
        leftCommandRemote1Title: 'Left command remote 1',
        rightCommandRemote1Title: 'Right command remote 1',
      },
      componentTypesTitle: 'Componenti',
      rimTitle: 'Rim',
      diskTitle: 'Disk',
      componentTypesSubs: {
        rimGroupsetTitle: 'Groupset',
        rimCaliperTitle: 'Caliper',
        rimControlTitle: 'Control',
        rimWheelTitle: 'Wheel',

        crankTitle: 'Crank',
        sprocketTitle: 'Sprocket',
        chainTitle: 'Chain',
        frontDerailleurTitle: 'Front derailleur',
        rearDerailleurTitle: 'Rear derailleur',

        diskGroupsetTitle: 'Groupset',
        diskCaliperTitle: 'Caliper',
        diskControlTitle: 'Control',
        diskWheelTitle: 'Wheel',
        rotorTitle: 'Rotor',
      },
      chassisTypeTitle: 'Telai',
      users: 'Utenti',
    },
    componentTypes: {
      titles: {
        listRimGroupsets: 'List rim groupset',
        newRimGroupset: 'Nuovo rim groupset',
        editRimGroupset: 'Modifica rim groupset',
        listRimCalipers: 'List rim caliper',
        newRimCaliper: 'Nuovo rim caliper',
        editRimCaliper: 'Modifica rim caliper',
        listRimControls: 'List rim control',
        newRimControl: 'Nuovo rim control',
        editRimControl: 'Modifica rim control',
        listRimWheels: 'List rim wheel',
        newRimWheel: 'Nuovo rim wheel',
        editRimWheel: 'Modifica rim wheel',

        listCranks: 'List crank',
        newCrank: 'Nuovo crank',
        editCrank: 'Modifica crank',
        listSprockets: 'List sprocket',
        newSprocket: 'Nuovo sprocket',
        editSprocket: 'Modifica sprocket',
        listChains: 'List chain',
        newChain: 'Nuovo chain',
        editChain: 'Modifica chain',

        listFrontDerailleurs: 'List front derailleur',
        newFrontDerailleur: 'Nuovo front derailleur',
        editFrontDerailleur: 'Modifica front derailleur',
        listRearDerailleurs: 'List rear derailleur',
        newRearDerailleur: 'Nuovo rear derailleur',
        editRearDerailleur: 'Modifica rear derailleur',
        listDiskGroupsets: 'List disk groupset',
        newDiskGroupset: 'Nuovo disk groupset',
        editDiskGroupset: 'Modifica disk groupset',
        listDiskCalipers: 'List disk caliper',
        newDiskCaliper: 'Nuovo disk caliper',
        editDiskCaliper: 'Modifica disk caliper',
        listDiskControls: 'List disk control',
        newDiskControl: 'Nuovo disk control',
        editDiskControl: 'Modifica disk control',
        listRotors: 'List rotor',
        newRotor: 'Nuovo rotor',
        editRotor: 'Modifica rotor',
        listDiskWheels: 'List disk wheel',
        newDiskWheel: 'Nuovo disk wheel',
        editDiskWheel: 'Modifica disk wheel',
      },
      table: {
        image: 'Immagine',
        name: 'Nome',
        actions: 'Azioni',
      },
      generalForm: {
        names: 'Nome',
        isCampagnolo: 'Campagnolo',
        hide: 'Nascosto',
        order: 'Ordine',
        tags: 'Tags',
        tagsPlaceholder: 'Inserisci i tag del componente',
        image: 'Immagine',
        logo: 'Logo',
      },
      crankForm: {
        sprocketSizes: 'Dimensione corone',
      },
      sprocketForm: {
        sprocketSizes: 'Dimensione corone',
      },
      rimGroupsetForm: {
        canHaveEps: 'Può avere EPS legacy',
        canHaveWirelessEps: 'Può avere EPS wireless',
        rimCaliperType: 'Rim caliper type',
        rimControlType: 'Rim control type',
        chainType: 'Chain type',
        crankType: 'Crank type',
        frontDerailleurType: 'Front derailleur type',
        rearDerailleurType: 'Rear derailleur type',
      },
      diskGroupsetForm: {
        canHaveEps: 'Può avere EPS legacy',
        canHaveWirelessEps: 'Può avere EPS wireless',
        diskCaliperType: 'Rim caliper type',
        diskControlType: 'Rim control type',
        chainType: 'Chain type',
        crankType: 'Crank type',
        frontDerailleurType: 'Front derailleur type',
        rearDerailleurType: 'Rear derailleur type',
        rotorType: 'Rotor type',
      },
    },
    chassisTypes: {
      listTitle: 'Lista telai',
      editTitle: 'Modifica telaio',
      table: {
        name: 'Nome',
        hide: 'Nascosto',
        actions: 'Azioni',
      },
      filter: {
        name: 'Nome',
      },
      form: {
        name: 'Nome',
        hide: 'Nascosto',
      },
    },
    notificationTypes: {
      listTitle: 'Notifiche componente',
      newTitle: 'Nuova notifica componente',
      editTitle: 'Modifica notifica componente',
      form: {
        titles: 'Titolo',
        shortDescriptions: 'Descrizione breve',
        longDescriptions: 'Descrizione lunga',
        disabled: 'Disabilitata',
        status: 'Stato',
        kilometers: 'Chilometri',
        months: 'Mesi',
        reminderKilometers: 'Chilometri per il richiamo',
        reminderMonths: 'Mesi per il richiamo',
        image: 'Immagine',
      },
      table: {
        title: 'Titolo',
        image: 'Immagine',
        actions: 'Azioni',
      },
    },
    legacyEpsFirmwares: {
      titles: {
        listInterface: 'Lista Interface firmware',
        newInterface: 'Nuovo Interface firmware',
        editInterface: 'Modifica Interface firmware',
        listPowerUnit: 'Lista PowerUnit firmware',
        newPowerUnit: 'Nuovo PowerUnit firmware',
        editPowerUnit: 'Modifica PowerUnit firmware',
        listNordic: 'Lista Nordic firmware',
        newNordic: 'Nuovo Nordic firmware',
        editNordic: 'Modifica Nordic firmware',
      },
      filter: {
        majorVersion: 'Major version',
        minorVersion: 'Minor version',
        patchVersion: 'Patch version',
        published: 'Pubblicato',
        racingTeam: 'Racing team',
      },
      table: {
        version: 'Version',
        hardwareRevision: 'Hardware revision',
        published: 'Pubblicato',
        racingTeam: 'Racing team',
        actions: 'Azioni',
      },
      form: {
        firmware: 'Firmware',
        descriptions: 'Descrizioni',
        notificationTitles: 'Titolo notifiche',
        notificationTexts: 'Test notifiche',
        racingTeam: 'Racing team',
        hardwareRevision: 'Hardware revision',
        version: 'Versione',
        published: 'Pubblicato',
      },
    },
    wirelessEpsFirmwares: {
      titles: {
        listBackGear: 'Lista back gear firmware',
        newBackGear: 'Nuovo back gear firmware',
        editBackGear: 'Modifica back gear firmware',
        listFrontGear: 'Lista front gear firmware',
        newFrontGear: 'Nuovo front gear firmware',
        editFrontGear: 'Modifica front gear firmware',
        listLeftCommand: 'Lista left command firmware',
        newLeftCommand: 'Nuovo left command firmware',
        editLeftCommand: 'Modifica left command firmware',
        listRightCommand: 'Lista right command firmware',
        newRightCommand: 'Nuovo right command firmware',
        editRightCommand: 'Modifica right command firmware',
        listLeftCommandRemote1: 'Lista left command remote 1 firmware',
        newLeftCommandRemote1: 'Nuovo left command remote 1 firmware',
        editLeftCommandRemote: 'Modifica left command remote 1firmware',
        listRightCommandRemote1: 'Lista right command remote 1 firmware',
        newRightCommandRemote1: 'Nuovo right command remote 1 firmware',
        editRightCommandRemote: 'Modifica right command remote 1firmware',
      },
      filter: {
        majorVersion: 'Major version',
        minorVersion: 'Minor version',
        patchVersion: 'Patch version',
        published: 'Pubblicato',
        baseLine: 'Base line',
      },
      table: {
        version: 'Version',
        published: 'Pubblicato',
        baseLine: 'Base line',
        dependsOn: 'Dipende da',
        actions: 'Azioni',
      },
      form: {
        firmware: 'Firmware',
        descriptions: 'Descrizioni',
        notificationTitles: 'Titolo notifiche',
        notificationTexts: 'Test notifiche',
        baseLine: 'Base Line',
        dependsOn: 'Dipende da',
        version: 'Versione',
        published: 'Pubblicato',
      },
    },
    users: {
      components: {
        me: {
          hello: 'Ciao {{displayName}}!',
          explanation: 'Ecco cosa puoi fare.',
        },
      },
      filter: {
        displayName: 'Nome',
        email: 'Email',
        country: 'Nazione',
        capability: 'Ruolo',
      },
      table: {
        displayName: 'Nome',
        email: 'Email',
        actions: 'Azioni',
      },
      form: {
        displayName: 'Nome completo',
        email: 'Email',
        manageableCountries: 'Nazioni amministrabili',
        capabilities: 'Ruoli',
        companyName: 'Ragione sociale',
        vat: 'Partita Iva/VAT',
        address: 'Indirizzo',
        city: 'Città',
        zipCode: 'Cap/Zip code',
        country: 'Nazione',
        state: 'Stato/Regione',
        webSite: 'Sito web',
        proShop: 'Prop shop',
        campyCode: 'Campy code',
        serviceCenter: 'Service center',
      },
      pages: {
        list: {
          title: 'Lista utenti',
        },
        edit: {
          title: 'Modifica utente',
        },
      },
    },
  },
};

export default translations;
