/* eslint-disable max-len */
import React from 'react';
import axios from 'axios';

import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Alert,
  Space,
  Input,
} from 'antd';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../commons/components/Loader';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

import config from '../../config';
import useDetail from '../../api/hooks/useDetail';

const fullPath = 'v2/component_types/chassis';

const EditView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [serverErrors, setServerErrors] = React.useState(null);

  const { currentUser } = React.useContext(AuthenticationContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    load,
    loading,
    data,
    error,
  } = useDetail(fullPath, id);

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        hide: data.hide,
      });
    }
  }, [data]);

  const renderLoading = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Loader />
      </Col>
    </Row>
  );

  const renderError = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Alert message={JSON.stringify(error)} type="error" showIcon />
      </Col>
    </Row>
  );

  const renderServerErrors = () => {
    if (serverErrors && serverErrors.length) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <Alert message={serverErrors.join('\n')} type="error" showIcon />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const onSubmit = async (formData) => {
    setServerErrors(null);
    try {
      await axios.put(`${config.baseApiUrl}${fullPath}/${id}`, {
        chassisType: formData,
      }, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      navigate(-1);
    }
    catch (e) {
      if (e.isAxiosError && e.response.status === 409) {
        setServerErrors(e.response.data.errors);
      }
      else {
        setServerErrors([t('commons.genericError')]);
      }
    }
  };

  if (loading) {
    return renderLoading();
  }

  if (error) {
    return renderError();
  }

  return (
    <>
      <Row justify="space-between">
        <Col>
          <h1>{t('chassisTypes.listTitle')}</h1>
        </Col>
      </Row>
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {renderServerErrors()}
          <Row gutter={16}>
            <Col span={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('chassisTypes.form.name')}
                    validateStatus={errors != null && errors.name != null ? 'error' : null}
                    help={errors && errors.name && errors.name.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
            <Col span={12}>
              <Controller
                name="hide"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('chassisTypes.form.hide')}
                    validateStatus={errors != null && errors.hide != null ? 'error' : null}
                    help={errors && errors.hide && errors.hide.message}
                  >
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16} justify="end">
            <Col>
              <Button type="primary" htmlType="submit">
                {t('commons.actions.edit')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  );
};

export default EditView;
