import React from 'react';
import PropTypes from 'prop-types';

const Base64FileInput = (props) => {
  const {
    onChange,
    fixedFiletype,
  } = props;

  const handleSelectFile = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      const filename = e.target.files[0].name;
      const reader = new FileReader();
      reader.onload = async (loadEvent) => {
        const dataUri = loadEvent.target.result;
        const splitted = dataUri.split(';');
        const filetype = splitted[0].split(':')[1];
        const base64 = splitted[1].split(',')[1];
        onChange({
          filetype: fixedFiletype || filetype,
          filename,
          base64,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    else {
      onChange(null);
    }
  };

  return (
    <input type="file" onChange={handleSelectFile} />
  );
};

const propTypes = {
  value: PropTypes.shape({
    filetype: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    base64: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  fixedFiletype: PropTypes.string,
};

const defaultProps = {
  value: null,
  fixedFiletype: null,
};

Base64FileInput.propTypes = propTypes;
Base64FileInput.defaultProps = defaultProps;

export default Base64FileInput;
