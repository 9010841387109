import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const Base64FileInput = (props) => {
  const {
    disabled,
    value,
    onChange,
  } = props;

  return (
    <Input disabled={disabled} value={value} onChange={onChange} />
  );
};

const propTypes = {
  value: PropTypes.shape({
    filetype: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    base64: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  value: null,
  disabled: false,
};

Base64FileInput.propTypes = propTypes;
Base64FileInput.defaultProps = defaultProps;

export default Base64FileInput;
