/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Button,
  Select,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const SprocketSizesInput = (props) => {
  const {
    onChange,
    value,
  } = props;

  const { t } = useTranslation();

  const realValue = React.useMemo(() => {
    if (value) {
      const result = value.map((v) => v.values);
      return result;
    }
    return [];
  }, [value]);

  const handleChange = (newValue, index) => {
    const realNewValue = newValue.map((v) => parseInt(v, 10))
      .filter((v) => !Number.isNaN(v));
    const result = [
      ...value.filter((v, i) => i < index),
      { values: [...realNewValue] },
      ...value.filter((v, i) => i > index),
    ];
    onChange(result);
  };

  const handleRemoveSprocketSize = (index) => {
    onChange([
      ...value.filter((v, i) => i < index),
      ...value.filter((v, i) => i > index),
    ]);
  };

  const handleAddSprocketSize = () => {
    if (value) {
      onChange([
        ...value,
        { values: [] },
      ]);
    }
    else {
      onChange([
        { values: [] },
      ]);
    }
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      {realValue.map((currentSprocketSize, index) => (
        <Row gutter={8} key={index}>
          <Col flex={4}>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder={t('components.sprocketSizesInput.sprocketPlaceholder')}
              onChange={(v) => handleChange(v, index)}
              value={currentSprocketSize}
            />
          </Col>
          <Col flex={1}>
            <Button
              danger
              shape="circle"
              icon={<MinusOutlined />}
              onClick={() => handleRemoveSprocketSize(index)}
            />
          </Col>
        </Row>
      ))}
      <Row justify="center">
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={handleAddSprocketSize}
          />
        </Col>
      </Row>
    </Space>
  );
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

const defaultProps = {
  value: null,
};

SprocketSizesInput.propTypes = propTypes;
SprocketSizesInput.defaultProps = defaultProps;

export default SprocketSizesInput;
