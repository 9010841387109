import React from 'react';

import {
  Row,
  Col,
  Button,
  Space,
  Alert,
  Table,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useList from '../../api/hooks/useList';
import Filter from '../components/Filter';

const ListView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loadConfig, setLoadConfig] = React.useState({
    pageIndex: 0,
    pageSize: 20,
    filter: {},
  });

  const {
    load,
    loading,
    data,
    error,
  } = useList('v2/component_types/chassis');

  React.useEffect(() => {
    load(loadConfig.pageIndex, loadConfig.pageSize, loadConfig.filter);
  }, [load, loadConfig]);

  const renderError = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Alert message={JSON.stringify(error)} type="error" showIcon />
      </Col>
    </Row>
  );

  const handleFilterChange = React.useCallback((newFilter) => {
    setLoadConfig({
      pageIndex: 0,
      pageSize: loadConfig.pageSize,
      filter: newFilter,
    });
  }, [setLoadConfig, loadConfig]);

  const handleTableChange = React.useCallback((page) => {
    setLoadConfig({
      ...loadConfig,
      pageIndex: page.current - 1,
      pageSize: page.pageSize,
    });
  }, [setLoadConfig, loadConfig]);

  if (error) {
    return renderError();
  }

  return (
    <Space size="large" style={{ width: '100%' }} direction="vertical">
      <Row justify="space-between">
        <Col>
          <h1>{t('chassisTypes.listTitle')}</h1>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Filter
            onSubmit={handleFilterChange}
            filter={loadConfig.filter}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Table
            loading={loading}
            dataSource={data ? data.list : []}
            rowKey={(record) => record.id}
            onChange={handleTableChange}
            pagination={{
              current: loadConfig.pageIndex + 1,
              pageSize: loadConfig.pageSize,
              total: data ? data.count : 0,
            }}
          >
            <Table.Column
              title={t('chassisTypes.table.name')}
              dataIndex="name"
              key="name"
            />
            <Table.Column
              title={t('chassisTypes.table.hide')}
              dataIndex="hide"
              key="hide"
              render={(hide) => (hide ? <CheckOutlined /> : <CloseOutlined />)}
            />
            <Table.Colum
              title={t('chassisTypes.table.actions')}
              key="action"
              render={(text, record) => (
                <Space size="middle">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => navigate(`/chassisTypes/${record.id}`)}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Space>
  );
};

export default ListView;
