import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Input,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

import config from '../../config';

const TranslatableTextInput = (props) => {
  const {
    value,
    onChange,
    size,
  } = props;

  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = React.useState(config.translationLanguages[0]);

  const options = React.useMemo(() => {
    const result = config.translationLanguages.map((l) => ({
      key: l,
      value: t(`commons.translationLanguages.${l}`),
    }));
    return result;
  }, [config.translationLanguages]);

  const handleCurrentLanguageChange = React.useCallback((v) => {
    setCurrentLanguage(v);
  }, [setCurrentLanguage]);

  const handleInputChange = React.useCallback((e) => {
    const newValue = e.target.value;
    let newData;
    if (newValue !== '') {
      newData = {
        ...value,
        [currentLanguage]: newValue,
      };
    }
    else {
      newData = { ...value };
      delete newData[currentLanguage];
    }
    if (Object.keys(newData).length === 0) {
      onChange(null);
    }
    else {
      onChange(newData);
    }
  }, [currentLanguage, value]);

  const inputValue = React.useMemo(() => {
    if (value) {
      const realValue = value[currentLanguage];
      if (realValue) {
        return realValue;
      }
      return '';
    }
    return '';
  }, [value, currentLanguage]);

  return (
    <Input.Group>
      <Row gutter={8}>
        <Col flex={1}>
          <Select value={currentLanguage} onChange={handleCurrentLanguageChange} style={{ width: '100%' }}>
            {options.map((option) => (
              <Select.Option key={option.key} value={option.key}>{option.value}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col flex={4}>
          {size === 'large' && (
            <Input.TextArea
              value={inputValue}
              onChange={handleInputChange}
              rows={4}
            />
          )}
          {size === 'small' && (
            <Input
              value={inputValue}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
    </Input.Group>
  );
};

const valueShape = config.translationLanguages.map((l) => ({
  [l]: PropTypes.string,
}));

const propTypes = {
  value: PropTypes.shape(valueShape),
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['large', 'small']),
};

const defaultProps = {
  value: {},
  size: 'small',
};

TranslatableTextInput.propTypes = propTypes;
TranslatableTextInput.defaultProps = defaultProps;

export default TranslatableTextInput;
