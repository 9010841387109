import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Form,
  Input,
  Image,
} from 'antd';
import { useTranslation } from 'react-i18next';

import config from '../../config';

const ImageInput = (props) => {
  const {
    onChange,
    value,
    type,
  } = props;

  const { t } = useTranslation();

  const fileRef = React.useRef();

  const [externalUrlValue, setExternalUrlValue] = React.useState(null);

  const imgSrc = React.useMemo(() => {
    let result = null;
    if (value) {
      if (value.id) {
        result = `${config.baseApiUrl}v1/${type}/${value.id}`;
      }
      else if (value.externalUrl) {
        result = value.externalUrl;
      }
      else {
        result = `data:${value.fileType};base64,${value.base64}`;
      }
    }
    return result;
  }, [value]);

  const handleUrlChange = (e) => {
    setExternalUrlValue(e.target.value);
  };

  const handleBlur = () => {
    if (fileRef && fileRef.current) {
      fileRef.current.value = null;
    }
    if (externalUrlValue && externalUrlValue.trim() !== '') {
      onChange({
        id: null,
        filetype: null,
        filename: null,
        base64: null,
        externalUrl: externalUrlValue,
      });
    }
    else {
      onChange(null);
    }
  };

  const handleSelectFile = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      const filename = e.target.files[0].name;
      const reader = new FileReader();
      reader.onload = async (loadEvent) => {
        const dataUri = loadEvent.target.result;
        const splitted = dataUri.split(';');
        const filetype = splitted[0].split(':')[1];
        const base64 = splitted[1].split(',')[1];
        onChange({
          id: null,
          filetype,
          filename,
          base64,
          externalUrl: null,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    else {
      onChange(null);
    }
  };

  return (
    <Row gutter={8}>
      <Col flex={1}>
        {imgSrc && (
          <Image width={150} src={imgSrc} alt="img" />
        )}
      </Col>
      <Col flex={1}>
        <Row>
          <Col flex={1}>
            <Form.Item
              label={t('components.imageInput.fileLabel')}
            >
              <input ref={fileRef} type="file" onChange={handleSelectFile} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <Form.Item
              label={t('components.imageInput.urlLabel')}
            >
              <Input value={externalUrlValue} onChange={handleUrlChange} onBlur={handleBlur} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    externalUrl: PropTypes.string,
    fileType: PropTypes.string,
    filename: PropTypes.string,
    base64: PropTypes.string,
  }),
  type: PropTypes.oneOf(['images', 'logos']).isRequired,
};

const defaultProps = {
  value: null,
};

ImageInput.propTypes = propTypes;
ImageInput.defaultProps = defaultProps;

export default ImageInput;
