import React from 'react';
import axios from 'axios';

import config from '../../config';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

const useList = (path) => {
  const { currentUser } = React.useContext(AuthenticationContext);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const load = React.useCallback(async (pageIndex, pageSize, filter) => {
    setError(null);
    setLoading(true);
    try {
      const result = await axios.get(`${config.baseApiUrl}${path}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        params: {
          page_index: pageIndex,
          page_size: pageSize,
          ...(filter || {}),
        },
      });
      setData(result.data);
    }
    catch (e) {
      setError(e);
    }
    setLoading(false);
  }, [path]);

  return {
    load,
    loading,
    data,
    error,
  };
};

export default useList;
