import React from 'react';
import {
  Col,
  Row,
  List,
  Avatar,
} from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';
import { USER_CAPABILITIES } from '../enums';

const Me = () => {
  const { currentUser } = React.useContext(AuthenticationContext);
  const { t } = useTranslation();

  const allCapabilities = React.useMemo(() => Object.entries(USER_CAPABILITIES), []);

  return (
    <>
      <Row>
        <Col xs={24}>
          <h1>{t('users.components.me.hello', { displayName: currentUser.displayName })}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <h4>{t('users.components.me.explanation', { displayName: currentUser.displayName })}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <List
            bordered
            dataSource={allCapabilities}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={(
                    <Avatar
                      icon={currentUser.capabilities.includes(item[1])
                        ? <CheckOutlined />
                        : <CloseOutlined />}
                    />
                  )}
                  title={t(`commons.userCapabilities.${item[0]}`)}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default Me;
