/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { Row, Col } from 'antd';

import queryString from 'query-string';

// import { USER_ROLES } from '../enum';
import config from '../../config';
import Loader from '../../commons/components/Loader';

const isValidToken = (storedToken) => {
  if (storedToken && storedToken.accessToken && storedToken.expiration) {
    const parsed = new Date(storedToken.expiration);
    if (parsed > new Date()) {
      return true;
    }
  }
  return false;
};

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = (props) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = React.useState(null);
  const [storedToken, setStoredToken] = React.useState(localStorage.getItem('token'));

  const logout = React.useCallback(() => {
    localStorage.removeItem('token');
    window.location.href = config.logoutUrl;
  }, []);

  const getMe = React.useCallback(async (st) => {
    if (isValidToken(st)) {
      const me = await axios.get(`${config.baseApiUrl}/v1/users/me`, {
        headers: {
          Authorization: `Bearer ${st.accessToken}`,
        },
      });
      return me.data;
    }
    logout();
    return null;
  }, []);

  React.useEffect(() => {
    if (!storedToken) {
      if (location && location.hash) {
        const parsedHash = queryString.parse(location.hash);
        if (parsedHash.access_token && parsedHash.expires_in) {
          const expiration = new Date();
          expiration.setSeconds(expiration.getSeconds() + parseInt(parsedHash.expires_in, 10));
          const stored = JSON.stringify({
            accessToken: parsedHash.access_token,
            expiration,
          });
          localStorage.setItem('token', stored);
          setStoredToken(stored);
        }
      }
      else {
        const URL = `${config.authorizationEndpoint}?client_id=${config.clientId}&redirect_uri=${config.redirectUri}&response_type=token`;
        window.location.href = URL;
      }
    }
  }, [storedToken, location, navigate, setStoredToken]);

  React.useEffect(() => {
    if (storedToken && !currentUser) {
      const perform = async () => {
        const parsed = JSON.parse(storedToken);
        const me = await getMe(parsed);
        setCurrentUser({
          ...me,
          ...parsed,
        });
        navigate('/dashboard');
      };
      perform();
    }
  }, [storedToken, currentUser, setCurrentUser]);

  const renderLoading = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Loader />
      </Col>
    </Row>
  );

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
      }}
    >
      {!currentUser && renderLoading()}
      {currentUser && children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const { Consumer: AuthenticationConsumer } = AuthenticationContext;
