import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.less';

import { AuthenticationProvider } from './authentications/contexts/AuthenticationContext';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';

const App = () => (
  <BrowserRouter>
    <AuthenticationProvider>
      <AuthenticatedLayout />
    </AuthenticationProvider>
  </BrowserRouter>
);

export default App;
