import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Alert,
  Space,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TranslatableTextInput from '../../commons/components/TranslatableTextInput';
import Base64FileInput from '../../commons/components/Base64FileInput';
import FirmwareVersionInput from '../../commons/components/FirmwareVersionInput';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

import config from '../../config';

const CreateView = (props) => {
  const {
    title,
    path,
  } = props;

  const { t } = useTranslation();

  const [serverErrors, setServerErrors] = React.useState(null);

  const { currentUser } = React.useContext(AuthenticationContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const fullPath = React.useMemo(() => `v2/epses/${path}`, [path]);

  const onSubmit = async (data) => {
    setServerErrors(null);
    try {
      await axios.post(`${config.baseApiUrl}${fullPath}`, {
        firmware: data,
      }, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      navigate(-1);
    }
    catch (e) {
      if (e.isAxiosError && e.response.status === 409) {
        setServerErrors(e.response.data.errors);
      }
      else {
        setServerErrors([t('commons.genericError')]);
      }
    }
  };

  const renderServerErrors = () => {
    if (serverErrors && serverErrors.length) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <Alert message={serverErrors.join('\n')} type="error" showIcon />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <>
      <h1>{title}</h1>
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {renderServerErrors()}
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="descriptions"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.descriptions')}
                    validateStatus={errors != null && errors.descriptions != null ? 'error' : null}
                    help={errors && errors.descriptions && errors.descriptions.message}
                  >
                    <TranslatableTextInput
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="notificationTitles"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.notificationTitles')}
                    validateStatus={errors != null && errors.notificationTitles != null ? 'error' : null}
                    help={errors && errors.notificationTitles && errors.notificationTitles.message}
                  >
                    <TranslatableTextInput
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="notificationTexts"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.notificationTexts')}
                    validateStatus={errors != null && errors.notificationTexts != null ? 'error' : null}
                    help={errors && errors.notificationTexts && errors.notificationTexts.message}
                  >
                    <TranslatableTextInput
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="version"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.version')}
                    validateStatus={errors != null && errors.version != null ? 'error' : null}
                    help={errors && errors.version && errors.version.message}
                  >
                    <FirmwareVersionInput
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="hardwareRevision"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.hardwareRevision')}
                    validateStatus={errors != null && errors.hardwareRevision != null ? 'error' : null}
                    help={errors && errors.hardwareRevision && errors.hardwareRevision.message}
                  >
                    <FirmwareVersionInput
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.firmware')}
                    validateStatus={errors != null && errors.file != null ? 'error' : null}
                    help={errors && errors.file && errors.file.message}
                  >
                    <Base64FileInput
                      fixedFiletype="application/mac-binhex"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="racingTeam"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.racingTeam')}
                    validateStatus={errors != null && errors.racingTeam != null ? 'error' : null}
                    help={errors && errors.racingTeam && errors.racingTeam.message}
                  >
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>

          <Row gutter={16} justify="end">
            <Col>
              <Button type="primary" htmlType="submit">
                {t('commons.actions.create')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  );
};

const propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

CreateView.propTypes = propTypes;

export default CreateView;
