// const config = {
//   defaultLanguage: 'it',
//   clientId: '8862fe8484158461aab2a9d934bcce7385ea50282c42d382053eb169810d9047',
//   authorizationEndpoint: 'https://s-mycampy.campagnolo.geekcups.com/oauth/authorize',
//   redirectUri: 'https://localhost:3000/login',
//   logoutUrl: 'https://s-mycampy.campagnolo.geekcups.com/users/sign_out?redirect_url=https://localhost:3000',
//   baseApiUrl: 'https://s-mycampy.campagnolo.geekcups.com/api/',
//   translationLanguages: ['en', 'it', 'fr', 'de', 'es', 'ja'],
// };

const config = {
  defaultLanguage: 'it',
  clientId: '8862fe8484158461aab2a9d934bcce7385ea50282c42d382053eb169810d9047',
  authorizationEndpoint: 'https://s-mycampy.campagnolo.geekcups.com/oauth/authorize',
  redirectUri: 'https://s-admin-mycampy.campagnolo.geekcups.com/login',
  logoutUrl: 'https://s-mycampy.campagnolo.geekcups.com/users/sign_out?redirect_url=https://s-admin-mycampy.campagnolo.geekcups.com',
  baseApiUrl: 'https://s-mycampy.campagnolo.geekcups.com/api/',
  translationLanguages: ['en', 'it', 'fr', 'de', 'es', 'ja'],
};

// const config = {
//   defaultLanguage: 'it',
//   clientId: '8862fe8484158461aab2a9d934bcce7385ea50282c42d382053eb169810d9047',
//   authorizationEndpoint: 'https://api-mycampy.campagnolo.com/oauth/authorize',
//   redirectUri: 'https://localhost:3000/login',
//   logoutUrl: 'https://api-mycampy.campagnolo.com/users/sign_out?redirect_url=https://localhost:3000',
//   baseApiUrl: 'https://api-mycampy.campagnolo.com/api/',
//   translationLanguages: ['en', 'it', 'fr', 'de', 'es', 'ja'],
// };

// const config = {
//   defaultLanguage: 'it',
//   clientId: '8862fe8484158461aab2a9d934bcce7385ea50282c42d382053eb169810d9047',
//   authorizationEndpoint: 'https://api-mycampy.campagnolo.com/oauth/authorize',
//   redirectUri: 'https://admin-mycampy.campagnolo.com/login',
//   logoutUrl: 'https://api-mycampy.campagnolo.com/users/sign_out?redirect_url=https://admin-mycampy.campagnolo.com',
//   baseApiUrl: 'https://api-mycampy.campagnolo.com/api/',
//   translationLanguages: ['en', 'it', 'fr', 'de', 'es', 'ja'],
// };

export default config;
