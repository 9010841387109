import React from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Row,
  Col,
  Space,
  Input,
  Button,
  Select,
} from 'antd';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useList from '../../api/hooks/useList';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

import { USER_CAPABILITIES } from '../enums';

const Filter = (props) => {
  const {
    filter,
    onSubmit,
  } = props;

  const { currentUser } = React.useContext(AuthenticationContext);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
  } = useForm({ defaultValues: filter });

  const {
    data: dataCountry,
    load: loadCountry,
  } = useList('v1/countries');

  React.useEffect(() => loadCountry(), [loadCountry]);

  const countryOptions = React.useMemo(() => {
    if (dataCountry) {
      let allCountries = dataCountry.list;
      if (currentUser.capabilities.includes('local_user_manager')) {
        allCountries = allCountries.filter((ac) => currentUser.manageableCountries.includes(ac));
      }
      const result = allCountries.map((c) => (
        <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>
      ));
      return result;
    }
    return [];
  }, [dataCountry, currentUser]);

  const capabilityOptions = React.useMemo(() => {
    const result = Object.keys(USER_CAPABILITIES).map((c) => (
      <Select.Option key={c} value={USER_CAPABILITIES[c]}>{t(`commons.userCapabilities.${c}`)}</Select.Option>
    ));
    return result;
  }, [USER_CAPABILITIES]);

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="display_name"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('users.filter.displayName')}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('users.filter.email')}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="country_id"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('users.filter.country')}
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {countryOptions}
                  </Select>
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="capability"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('users.filter.capability')}
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {capabilityOptions}
                  </Select>
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle" justify="center">
          <Col>
            <Button type="primary" htmlType="submit">
              {t('commons.actions.search')}
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    major_version: PropTypes.number,
    minor_version: PropTypes.number,
    patch_version: PropTypes.number,
    published: PropTypes.bool,
    base_line: PropTypes.bool,
  }).isRequired,
};

Filter.propTypes = propTypes;

export default Filter;
