import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import {
  Row,
  Col,
  Form,
  Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';

import GeneralForm from './GeneralForm';
import ComponentTypeInput from '../../commons/components/ComponentTypeInput';

const RimGroupsetForm = (props) => {
  const {
    onSubmit,
    serverErrors,
    formObject,
    buttonText,
  } = props;

  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = formObject;

  return (
    <GeneralForm
      formObject={formObject}
      onSubmit={onSubmit}
      serverErrors={serverErrors}
      buttonText={buttonText}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Controller
            name="canHaveEps"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.canHaveEps')}
                validateStatus={errors != null && errors.canHaveEps != null ? 'error' : null}
                help={errors && errors.canHaveEps && errors.canHaveEps.message}
              >
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                />
              </Form.Item>
            )}
            defaultValue={false}
          />
        </Col>
        <Col flex={1}>
          <Controller
            name="canHaveWirelessEps"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.canHaveWirelessEps')}
                validateStatus={errors != null && errors.canHaveWirelessEps != null ? 'error' : null}
                help={errors && errors.canHaveWirelessEps && errors.canHaveWirelessEps.message}
              >
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                />
              </Form.Item>
            )}
            defaultValue={false}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Controller
            name="rimCaliperType"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.rimCaliperType')}
                validateStatus={errors != null && errors.rimCaliperType != null ? 'error' : null}
                help={errors && errors.rimCaliperType && errors.rimCaliperType.message}
              >
                <ComponentTypeInput
                  value={field.value}
                  onChange={field.onChange}
                  serverPath="rim_calipers"
                />
              </Form.Item>
            )}
            rules={{
              required: t('validations.required'),
            }}
          />
        </Col>
        <Col flex={1}>
          <Controller
            name="rimControlType"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.rimControlType')}
                validateStatus={errors != null && errors.rimControlType != null ? 'error' : null}
                help={errors && errors.rimControlType && errors.rimControlType.message}
              >
                <ComponentTypeInput
                  value={field.value}
                  onChange={field.onChange}
                  serverPath="rim_controls"
                />
              </Form.Item>
            )}
            rules={{
              required: t('validations.required'),
            }}
          />
        </Col>
        <Col flex={1}>
          <Controller
            name="chainType"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.chainType')}
                validateStatus={errors != null && errors.chainType != null ? 'error' : null}
                help={errors && errors.chainType && errors.chainType.message}
              >
                <ComponentTypeInput
                  value={field.value}
                  onChange={field.onChange}
                  serverPath="chains"
                />
              </Form.Item>
            )}
            rules={{
              required: t('validations.required'),
            }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Controller
            name="crankType"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.crankType')}
                validateStatus={errors != null && errors.crankType != null ? 'error' : null}
                help={errors && errors.crankType && errors.crankType.message}
              >
                <ComponentTypeInput
                  value={field.value}
                  onChange={field.onChange}
                  serverPath="cranks"
                />
              </Form.Item>
            )}
            rules={{
              required: t('validations.required'),
            }}
          />
        </Col>
        <Col flex={1}>
          <Controller
            name="frontDerailleurType"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.frontDerailleurType')}
                validateStatus={errors != null && errors.frontDerailleurType != null ? 'error' : null}
                help={errors && errors.frontDerailleurType && errors.frontDerailleurType.message}
              >
                <ComponentTypeInput
                  value={field.value}
                  onChange={field.onChange}
                  serverPath="front_derailleurs"
                />
              </Form.Item>
            )}
            rules={{
              required: t('validations.required'),
            }}
          />
        </Col>
        <Col flex={1}>
          <Controller
            name="rearDerailleurType"
            control={control}
            render={({ field }) => (
              <Form.Item
                label={t('componentTypes.rimGroupsetForm.rearDerailleurType')}
                validateStatus={errors != null && errors.rearDerailleurType != null ? 'error' : null}
                help={errors && errors.rearDerailleurType && errors.rearDerailleurType.message}
              >
                <ComponentTypeInput
                  value={field.value}
                  onChange={field.onChange}
                  serverPath="rear_derailleurs"
                />
              </Form.Item>
            )}
            rules={{
              required: t('validations.required'),
            }}
          />
        </Col>
      </Row>
    </GeneralForm>
  );
};

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  formObject: PropTypes.any.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const defaultProps = {
  serverErrors: null,
};

RimGroupsetForm.propTypes = propTypes;
RimGroupsetForm.defaultProps = defaultProps;

export default RimGroupsetForm;
