export const USER_CAPABILITIES = {
  FIRMWARE_MANAGER: 'firmware_manager',
  USER_MANAGER: 'user_manager',
  COMPONENT_TYPE_MANAGER: 'component_type_manager',
  MECHANIC: 'mechanic',
  SYSTEM_ADMIN: 'system_admin',
  LOCAL_USER_MANAGER: 'local_user_manager',
  RACING_TEAM: 'racing_team',
  BUSINESS_INTELLIGENCE: 'business_intelligence',
};

export default USER_CAPABILITIES;
