import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import config from '../../config';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';
import NotificationForm from '../components/NotificationForm';

const CreateView = (props) => {
  const {
    serverPath,
    clientPath,
  } = props;

  const [serverErrors, setServerErrors] = React.useState(null);

  const { currentUser } = React.useContext(AuthenticationContext);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id } = useParams();

  const formObject = useForm();

  const fullPath = React.useMemo(() => `v2/component_types/${serverPath}/${id}/notifications`, [serverPath, id]);

  const handleSubmit = async (data) => {
    setServerErrors(null);
    try {
      const result = await axios.post(`${config.baseApiUrl}${fullPath}`, {
        notificationType: data,
      }, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      navigate(`/componentTypes/${clientPath}/${result.data.id}`);
    }
    catch (e) {
      if (e.isAxiosError && e.response.status === 409) {
        setServerErrors(e.response.data.errors);
      }
      else {
        setServerErrors([t('commons.genericError')]);
      }
    }
  };

  return (
    <>
      <h1>{t('notificationTypes.newTitle')}</h1>
      <NotificationForm
        onSubmit={handleSubmit}
        buttonText={t('commons.actions.create')}
        serverErrors={serverErrors}
        formObject={formObject}
      />
    </>
  );
};

export default CreateView;

const propTypes = {
  serverPath: PropTypes.string.isRequired,
  clientPath: PropTypes.string.isRequired,
};

CreateView.propTypes = propTypes;
