/* eslint-disable max-len */
import React from 'react';
import axios from 'axios';

import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Alert,
  Space,
  Input,
  Select,
} from 'antd';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../commons/components/Loader';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

import config from '../../config';
import useDetail from '../../api/hooks/useDetail';
import useList from '../../api/hooks/useList';

import { USER_CAPABILITIES } from '../enums';

const EditView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [serverErrors, setServerErrors] = React.useState(null);

  const { currentUser } = React.useContext(AuthenticationContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    load,
    loading,
    data,
    error,
  } = useDetail('v1/users', id);

  const {
    data: dataCountry,
    load: loadCountry,
  } = useList('v1/countries');

  React.useEffect(() => {
    load();
    loadCountry();
  }, [loadCountry, load]);
  React.useEffect(() => loadCountry(), [loadCountry]);

  const countryOptions = React.useMemo(() => {
    if (dataCountry) {
      let allCountries = dataCountry.list;
      if (currentUser.capabilities.includes('local_user_manager')) {
        allCountries = allCountries.filter((ac) => currentUser.manageableCountries.includes(ac));
      }
      const result = allCountries.map((c) => (
        <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>
      ));
      return result;
    }
    return [];
  }, [dataCountry, currentUser]);

  const capabilityOptions = React.useMemo(() => {
    // console.log(USER_CAPABILITIES);
    const result = Object.keys(USER_CAPABILITIES).map((c) => (
      <Select.Option key={c} value={USER_CAPABILITIES[c]}>{t(`commons.userCapabilities.${c}`)}</Select.Option>
    ));
    return result;
  }, [USER_CAPABILITIES]);

  React.useEffect(() => {
    if (data) {
      let mechanicProfile = {};
      if (data.mechanicProfile) {
        mechanicProfile = {
          companyName: data.mechanicProfile.companyName,
          vat: data.mechanicProfile.vat,
          address: data.mechanicProfile.address,
          city: data.mechanicProfile.city,
          zipCode: data.mechanicProfile.zipCode,
          country: data.mechanicProfile.country ? data.mechanicProfile.country.id : null,
          state: data.mechanicProfile.state,
          webSite: data.mechanicProfile.webSite,
          proShop: data.mechanicProfile.proShop,
          campyCode: data.mechanicProfile.campyCode,
          serviceCenter: data.mechanicProfile.serviceCenter,
        };
      }
      reset({
        displayName: data.displayName,
        email: data.email,
        manageableCountries: data.manageableCountries,
        capabilities: data.capabilities,
        mechanicProfile,
      });
    }
  }, [data]);

  const renderLoading = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Loader />
      </Col>
    </Row>
  );

  const renderError = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Alert message={JSON.stringify(error)} type="error" showIcon />
      </Col>
    </Row>
  );

  const renderServerErrors = () => {
    if (serverErrors && serverErrors.length) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <Alert message={serverErrors.join('\n')} type="error" showIcon />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const onSubmit = async (formData) => {
    setServerErrors(null);
    try {
      if (formData.manageableCountries && formData.manageableCountries.length) {
        // eslint-disable-next-line no-param-reassign
        formData.manageableCountries = formData.manageableCountries.map((mc) => mc.id);
      }
      await axios.put(`${config.baseApiUrl}v1/users/${id}`, {
        user: formData,
      }, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      navigate(-1);
    }
    catch (e) {
      if (e.isAxiosError && e.response.status === 409) {
        setServerErrors(e.response.data.errors);
      }
      else {
        setServerErrors([t('commons.genericError')]);
      }
    }
  };

  if (loading) {
    return renderLoading();
  }

  if (error) {
    return renderError();
  }

  return (
    <>
      <Row justify="space-between">
        <Col>
          <h1>{t('users.pages.edit.title')}</h1>
        </Col>
      </Row>
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {renderServerErrors()}
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="displayName"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.displayName')}
                    validateStatus={errors != null && errors.displayName != null ? 'error' : null}
                    help={errors && errors.displayName && errors.displayName.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.email')}
                    validateStatus={errors != null && errors.email != null ? 'error' : null}
                    help={errors && errors.email && errors.email.message}
                  >
                    <Input
                      disabled
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="manageableCountries"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.manageableCountries')}
                    validateStatus={errors != null && errors.manageableCountries != null ? 'error' : null}
                    help={errors && errors.manageableCountries && errors.manageableCountries.message}
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      style={{ width: '100%' }}
                      value={field.value ? field.value.map((v) => v.id) : []}
                      onChange={(e) => {
                        field.onChange(dataCountry.list.filter(((k) => e.includes(k.id))));
                      }}
                    >
                      {countryOptions}
                    </Select>
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="capabilities"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.capabilities')}
                    validateStatus={errors != null && errors.capabilities != null ? 'error' : null}
                    help={errors && errors.capabilities && errors.capabilities.message}
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      style={{ width: '100%' }}
                      value={field.value}
                      onChange={field.onChange}
                    >
                      {capabilityOptions}
                    </Select>
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              Mechanic profile
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.companyName"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.companyName')}
                    validateStatus={errors != null && errors.companyName != null ? 'error' : null}
                    help={errors && errors.companyName && errors.companyName.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.vat"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.vat')}
                    validateStatus={errors != null && errors.vat != null ? 'error' : null}
                    help={errors && errors.vat && errors.vat.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.address"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.address')}
                    validateStatus={errors != null && errors.address != null ? 'error' : null}
                    help={errors && errors.address && errors.address.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.city"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.city')}
                    validateStatus={errors != null && errors.city != null ? 'error' : null}
                    help={errors && errors.city && errors.city.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.zipCode"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.zipCode')}
                    validateStatus={errors != null && errors.zipCode != null ? 'error' : null}
                    help={errors && errors.zipCode && errors.zipCode.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.country"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.country')}
                    validateStatus={errors != null && errors.country != null ? 'error' : null}
                    help={errors && errors.country && errors.country.message}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      value={field.value}
                      onChange={field.onChange}
                    >
                      {countryOptions}
                    </Select>
                  </Form.Item>
                )}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.state"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.state')}
                    validateStatus={errors != null && errors.state != null ? 'error' : null}
                    help={errors && errors.state && errors.state.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.webSite"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.webSite')}
                    validateStatus={errors != null && errors.webSite != null ? 'error' : null}
                    help={errors && errors.webSite && errors.webSite.message}
                  >
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.proShop"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.proShop')}
                    validateStatus={errors != null && errors.proShop != null ? 'error' : null}
                    help={errors && errors.proShop && errors.proShop.message}
                  >
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
                defaultValue={false}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.campyCode"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.campyCode')}
                    validateStatus={errors != null && errors.campyCode != null ? 'error' : null}
                    help={errors && errors.campyCode && errors.campyCode.message}
                  >
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
                defaultValue={false}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="mechanicProfile.serviceCenter"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('users.form.serviceCenter')}
                    validateStatus={errors != null && errors.serviceCenter != null ? 'error' : null}
                    help={errors && errors.serviceCenter && errors.serviceCenter.message}
                  >
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
                defaultValue={false}
              />
            </Col>
          </Row>

          <Row gutter={16} justify="end">
            <Col>
              <Button type="primary" htmlType="submit">
                {t('commons.actions.edit')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  );
};

export default EditView;
