/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import axios from 'axios';

import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Alert,
  Space,
  InputNumber,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

import config from '../../config';

import TranslatableTextInput from '../../commons/components/TranslatableTextInput';
import ImageInput from '../../commons/components/ImageInput';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

const GeneralForm = (props) => {
  const {
    onSubmit,
    serverErrors,
    children,
    formObject,
    buttonText,
  } = props;

  const { t } = useTranslation();

  const { currentUser } = React.useContext(AuthenticationContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formObject;

  const handlePreSubmit = React.useCallback(async (data) => {
    let imageId = data.image.id;
    let logoId = data.logo.id;
    if (imageId === null) { // significa che è stata toccata
      try {
        const result = await axios.post(`${config.baseApiUrl}/v1/images`, {
          image: data.image,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        });
        imageId = result.data.id;
      }
      catch (e) {
        alert('Errore server nell\'upload dell\'immagine');
      }
    }

    if (logoId === null) { // significa che è stata toccata
      try {
        const result = await axios.post(`${config.baseApiUrl}/v1/logos`, {
          logo: data.logo,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        });
        logoId = result.data.id;
      }
      catch (e) {
        alert('Errore server nell\'upload dell logo');
      }
    }

    if (imageId && logoId) {
      const realData = {
        ...data,
        image: {
          id: imageId,
        },
        logo: {
          id: logoId,
        },
      };
      onSubmit(realData);
    }
  }, []);

  const renderServerErrors = () => {
    if (serverErrors && serverErrors.length) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <Alert message={serverErrors.join('\n')} type="error" showIcon />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(handlePreSubmit)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {renderServerErrors()}
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="names"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('componentTypes.generalForm.names')}
                  validateStatus={errors != null && errors.names != null ? 'error' : null}
                  help={errors && errors.names && errors.names.message}
                >
                  <TranslatableTextInput
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="isCampagnolo"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('componentTypes.generalForm.isCampagnolo')}
                  validateStatus={errors != null && errors.isCampagnolo != null ? 'error' : null}
                  help={errors && errors.isCampagnolo && errors.isCampagnolo.message}
                >
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
              defaultValue={false}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="hide"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('componentTypes.generalForm.hide')}
                  validateStatus={errors != null && errors.hide != null ? 'error' : null}
                  help={errors && errors.hide && errors.hide.message}
                >
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
              defaultValue={false}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="order"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('componentTypes.generalForm.order')}
                  validateStatus={errors != null && errors.order != null ? 'error' : null}
                  help={errors && errors.order && errors.order.message}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                    parser={parseInt}
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('componentTypes.generalForm.tags')}
                  validateStatus={errors != null && errors.tags != null ? 'error' : null}
                  help={errors && errors.tags && errors.tags.message}
                >
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder={t('componentTypes.generalForm.tagsPlaceholder')}
                    onChange={field.onChange}
                    value={field.value}
                  />
                </Form.Item>
              )}
              defaultValue={[]}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('componentTypes.generalForm.image')}
                  validateStatus={errors != null && errors.image != null ? 'error' : null}
                  help={errors && errors.image && errors.image.message}
                >
                  <ImageInput
                    value={field.value}
                    onChange={field.onChange}
                    type="images"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
          <Col flex={1}>
            <Controller
              name="logo"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('componentTypes.generalForm.logo')}
                  validateStatus={errors != null && errors.logo != null ? 'error' : null}
                  help={errors && errors.logo && errors.logo.message}
                >
                  <ImageInput
                    value={field.value}
                    onChange={field.onChange}
                    type="logos"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        {children}

        <Row gutter={16} justify="end">
          <Col>
            <Button type="primary" htmlType="submit">
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formObject: PropTypes.any.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const defaultProps = {
  serverErrors: null,
};

GeneralForm.propTypes = propTypes;
GeneralForm.defaultProps = defaultProps;

export default GeneralForm;
