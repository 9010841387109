import React from 'react';

import {
  Layout,
  Menu,
} from 'antd';
import {
  Routes,
  Route,
  Link,
  Outlet,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DashboardView from '../dashboards/views/DashboardView';

import ComponentTypeListView from '../componentTypes/views/ListView';
import ComponentTypeCreateView from '../componentTypes/views/CreateView';
import ComponentTypeEditView from '../componentTypes/views/EditView';

import ChassisTypeListView from '../chassisTypes/views/ListView';
import ChassisTypeEditView from '../chassisTypes/views/EditView';

import NotificationTypeListView from '../notificationTypes/views/ListView';
import NotificationTypeCreateView from '../notificationTypes/views/CreateView';
import NotificationTypeEditView from '../notificationTypes/views/EditView';

import RimGroupsetForm from '../componentTypes/components/RimGroupsetForm';
import RimCaliperForm from '../componentTypes/components/RimCaliperForm';
import RimControlForm from '../componentTypes/components/RimControlForm';
import RimWheelForm from '../componentTypes/components/RimWheelForm';

import DiskGroupsetForm from '../componentTypes/components/DiskGroupsetForm';
import DiskCaliperForm from '../componentTypes/components/DiskCaliperForm';
import DiskControlForm from '../componentTypes/components/DiskControlForm';
import RotorForm from '../componentTypes/components/RotorForm';
import DiskWheelForm from '../componentTypes/components/DiskWheelForm';

import CrankForm from '../componentTypes/components/CrankForm';
import SprocketForm from '../componentTypes/components/SprocketForm';
import ChainForm from '../componentTypes/components/ChainForm';
import FrontDerailleurForm from '../componentTypes/components/FrontDerailleurForm';
import RearDerailleurForm from '../componentTypes/components/RearDerailleurForm';

import LegacyEpsFirmwareListView from '../legacyEpsFirmwares/views/ListView';
import LegacyEpsFirmwareCreateView from '../legacyEpsFirmwares/views/CreateView';
import LegacyEpsFirmwareEditView from '../legacyEpsFirmwares/views/EditView';

import WirelessEpsFirmwareListView from '../wirelessEpsFirmwares/views/ListView';
import WirelessEpsFirmwareCreateView from '../wirelessEpsFirmwares/views/CreateView';
import WirelessEpsFirmwareEditView from '../wirelessEpsFirmwares/views/EditView';

import UserListView from '../users/views/ListView';
import UserEditView from '../users/views/EditView';

import { AuthenticationContext } from '../authentications/contexts/AuthenticationContext';

const {
  Header,
  Content,
} = Layout;

const AuthenticatedLayout = () => {
  const { currentUser } = React.useContext(AuthenticationContext);
  const { t } = useTranslation();

  const renderMenu = () => {
    const result = [];

    if (currentUser.capabilities.includes('component_type_manager')) {
      result.push(
        <Menu.SubMenu id="componentTypes" key="componentTypes" title={t('menu.componentTypesTitle')}>
          <Menu.SubMenu key="rim" title={t('menu.rimTitle')}>
            <Menu.Item id="rimGroupset">
              <Link to="/componentTypes/rimGroupsets">
                {t('menu.componentTypesSubs.rimGroupsetTitle')}
              </Link>
            </Menu.Item>
            <Menu.Item id="rimCaliper">
              <Link to="/componentTypes/rimCalipers">
                {t('menu.componentTypesSubs.rimCaliperTitle')}
              </Link>
            </Menu.Item>
            <Menu.Item id="rimControl">
              <Link to="/componentTypes/rimControls">
                {t('menu.componentTypesSubs.rimControlTitle')}
              </Link>
            </Menu.Item>
            <Menu.Item id="rimWheel">
              <Link to="/componentTypes/rimWheels">
                {t('menu.componentTypesSubs.rimWheelTitle')}
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="disk" title={t('menu.diskTitle')}>
            <Menu.Item id="diskGroupset">
              <Link to="/componentTypes/diskGroupsets">
                {t('menu.componentTypesSubs.diskGroupsetTitle')}
              </Link>
            </Menu.Item>
            <Menu.Item id="diskCaliper">
              <Link to="/componentTypes/diskCalipers">
                {t('menu.componentTypesSubs.diskCaliperTitle')}
              </Link>
            </Menu.Item>
            <Menu.Item id="diskControl">
              <Link to="/componentTypes/diskControls">
                {t('menu.componentTypesSubs.diskControlTitle')}
              </Link>
            </Menu.Item>
            <Menu.Item id="rotor">
              <Link to="/componentTypes/rotors">
                {t('menu.componentTypesSubs.rotorTitle')}
              </Link>
            </Menu.Item>
            <Menu.Item id="diskWheel">
              <Link to="/componentTypes/diskWheels">
                {t('menu.componentTypesSubs.diskWheelTitle')}
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item id="cranks">
            <Link to="/componentTypes/cranks">
              {t('menu.componentTypesSubs.crankTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="sprockets">
            <Link to="/componentTypes/sprockets">
              {t('menu.componentTypesSubs.sprocketTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="chains">
            <Link to="/componentTypes/chains">
              {t('menu.componentTypesSubs.chainTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="frontDerailleurs">
            <Link to="/componentTypes/frontDerailleurs">
              {t('menu.componentTypesSubs.frontDerailleurTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="rearDerailleurs">
            <Link to="/componentTypes/rearDerailleurs">
              {t('menu.componentTypesSubs.rearDerailleurTitle')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>,
      );

      result.push(((
        <Menu.Item id="chassisTypes">
          <Link to="/chassisTypes">
            {t('menu.chassisTypeTitle')}
          </Link>
        </Menu.Item>
      )));
    }
    if (currentUser.capabilities.includes('firmware_manager')) {
      result.push((
        <Menu.SubMenu id="legacyEpsFirwmware" key="legacyEpsFirwmware" title={t('menu.legacyEpsFirmwaresTitle')}>
          <Menu.Item id="legacyEpsFirmwaresInterface">
            <Link to="/legacyEpsFirmwares/interface">
              {t('menu.legacyEpsFirmwaresSubs.interfaceTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="legacyEpsFirmwaresPowerUnit">
            <Link to="/legacyEpsFirmwares/powerUnit">
              {t('menu.legacyEpsFirmwaresSubs.powerUnitTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="legacyEpsFirmwaresNordic">
            <Link to="/legacyEpsFirmwares/nordic">
              {t('menu.legacyEpsFirmwaresSubs.nordicTitle')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
      ));
      result.push(
        <Menu.SubMenu id="wirelessEpsFirmware" key="wirelessEpsFirmware" title={t('menu.wirelessEpsFirmwaresTitle')}>
          <Menu.Item id="wirelessEpsFirmwareBackGear">
            <Link to="/wirelessEpsFirmwares/backGears">
              {t('menu.wirelessEpsFirmwaresSubs.backGearTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="wirelessEpsFirmwareFrontGear">
            <Link to="/wirelessEpsFirmwares/frontGears">
              {t('menu.wirelessEpsFirmwaresSubs.frontGearTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="wirelessEpsFirmwareLeftCommand">
            <Link to="/wirelessEpsFirmwares/leftCommands">
              {t('menu.wirelessEpsFirmwaresSubs.leftCommandTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="wirelessEpsFirmwareRightCommand">
            <Link to="/wirelessEpsFirmwares/rightCommands">
              {t('menu.wirelessEpsFirmwaresSubs.rightCommandTitle')}
            </Link>
          </Menu.Item>
          <Menu.Item id="wirelessEpsFirmwareLeftCommandRemote1">
            <Link to="/wirelessEpsFirmwares/leftCommandRemote1s">
              {t('menu.wirelessEpsFirmwaresSubs.leftCommandRemote1Title')}
            </Link>
          </Menu.Item>
          <Menu.Item id="wirelessEpsFirmwareRightCommandRemote1">
            <Link to="/wirelessEpsFirmwares/rightCommandRemote1s">
              {t('menu.wirelessEpsFirmwaresSubs.rightCommandRemote1Title')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>,
      );
    }
    if (currentUser.capabilities.includes('user_manager') || currentUser.capabilities.includes('local_user_manager')) {
      result.push((
        <Menu.Item id="users">
          <Link to="/users">
            {t('menu.users')}
          </Link>
        </Menu.Item>
      ));
    }
    return (
      <Menu
        theme="dark"
        mode="horizontal"
      >
        {result}
      </Menu>
    );
  };

  const renderRoutes = () => {
    const result = [];
    result.push(<Route key="dashboard" path="/dashboard" element={<DashboardView />} />);

    if (currentUser.capabilities.includes('component_type_manager')) {
      result.push((
        <Route key="componentTypes" path="/componentTypes" element={<Outlet />}>
          <Route id="rimGroupsets" path="rimGroupsets" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listRimGroupsets')}
                  clientPath="rimGroupsets"
                  serverPath="rim_groupsets"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="rimGroupsets"
                  serverPath="rim_groupsets"
                  title={t('componentTypes.titles.newRimGroupset')}
                  component="rimGroupsetType"
                  FormComponent={RimGroupsetForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="rim_groupsets"
                    title={t('componentTypes.titles.editRimGroupset')}
                    component="rimGroupsetType"
                    FormComponent={RimGroupsetForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="rimGroupsets"
                      serverPath="rim_groupsets"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="rimGroupsets"
                      serverPath="rim_groupsets"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="rimGroupsets"
                      serverPath="rim_groupsets"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="rimCalipers" path="rimCalipers" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listRimCalipers')}
                  clientPath="rimCalipers"
                  serverPath="rim_calipers"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="rimCalipers"
                  serverPath="rim_calipers"
                  title={t('componentTypes.titles.newRimCaliper')}
                  component="rimCaliperType"
                  FormComponent={RimCaliperForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="rim_calipers"
                    title={t('componentTypes.titles.editRimCaliper')}
                    component="rimCaliperType"
                    FormComponent={RimCaliperForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="rimCalipers"
                      serverPath="rim_calipers"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="rimCalipers"
                      serverPath="rim_calipers"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="rimCalipers"
                      serverPath="rim_calipers"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="rimControls" path="rimControls" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listRimControls')}
                  clientPath="rimControls"
                  serverPath="rim_controls"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="rimControls"
                  serverPath="rim_controls"
                  title={t('componentTypes.titles.newRimControl')}
                  component="rimControlType"
                  FormComponent={RimControlForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="rim_controls"
                    title={t('componentTypes.titles.editRimControl')}
                    component="rimControlType"
                    FormComponent={RimControlForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="rimControls"
                      serverPath="rim_controls"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="rimControls"
                      serverPath="rim_controls"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="rimControls"
                      serverPath="rim_controls"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="rimWheels" path="rimWheels" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listRimWheels')}
                  clientPath="rimWheels"
                  serverPath="rim_wheels"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="rimWheels"
                  serverPath="rim_wheels"
                  title={t('componentTypes.titles.newRimWheel')}
                  component="rimWheelType"
                  FormComponent={RimWheelForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="rim_wheels"
                    title={t('componentTypes.titles.editRimWheel')}
                    component="rimWheelType"
                    FormComponent={RimControlForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="rimWheels"
                      serverPath="rim_wheels"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="rimWheels"
                      serverPath="rim_wheels"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="rimWheels"
                      serverPath="rim_wheels"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="diskGroupsets" path="diskGroupsets" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listDiskGroupsets')}
                  clientPath="diskGroupsets"
                  serverPath="disk_groupsets"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="diskGroupsets"
                  serverPath="disk_groupsets"
                  title={t('componentTypes.titles.newDiskGroupset')}
                  component="diskGroupsetType"
                  FormComponent={DiskGroupsetForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="disk_groupsets"
                    title={t('componentTypes.titles.editDiskGroupset')}
                    component="diskGroupsetType"
                    FormComponent={DiskGroupsetForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="diskGroupsets"
                      serverPath="disk_groupsets"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="diskGroupsets"
                      serverPath="disk_groupsets"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="diskGroupsets"
                      serverPath="disk_groupsets"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="diskCalipers" path="diskCalipers" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listDiskCalipers')}
                  clientPath="diskCalipers"
                  serverPath="disk_calipers"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="diskCalipers"
                  serverPath="disk_calipers"
                  title={t('componentTypes.titles.newDiskCaliper')}
                  component="diskCaliperType"
                  FormComponent={DiskCaliperForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="disk_calipers"
                    title={t('componentTypes.titles.editDiskCaliper')}
                    component="diskCaliperType"
                    FormComponent={DiskCaliperForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="diskCalipers"
                      serverPath="disk_calipers"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="diskCalipers"
                      serverPath="disk_calipers"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="diskCalipers"
                      serverPath="disk_calipers"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="diskControls" path="diskControls" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listDiskControls')}
                  clientPath="diskControls"
                  serverPath="disk_controls"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="diskControls"
                  serverPath="disk_controls"
                  title={t('componentTypes.titles.newDiskControl')}
                  component="diskControlType"
                  FormComponent={DiskControlForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="disk_controls"
                    title={t('componentTypes.titles.editDiskControl')}
                    component="diskControlType"
                    FormComponent={DiskControlForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="diskControls"
                      serverPath="disk_controls"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="diskControls"
                      serverPath="disk_controls"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="diskControls"
                      serverPath="disk_controls"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="rotors" path="rotors" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listRotors')}
                  serverPath="rotors"
                  clientPath="rotors"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  serverPath="rotors"
                  clientPath="rotors"
                  title={t('componentTypes.titles.newRotor')}
                  component="rotorType"
                  FormComponent={RotorForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="rotors"
                    title={t('componentTypes.titles.editRotor')}
                    component="rotorType"
                    FormComponent={RotorForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="rotors"
                      serverPath="rotors"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="rotors"
                      serverPath="rotors"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="rotors"
                      serverPath="rotors"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="diskWheels" path="diskWheels" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listDiskWheels')}
                  clientPath="diskWheels"
                  serverPath="disk_wheels"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  clientPath="diskWheels"
                  serverPath="disk_wheels"
                  title={t('componentTypes.titles.newDiskWheel')}
                  component="diskWheelType"
                  FormComponent={DiskWheelForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="disk_wheels"
                    title={t('componentTypes.titles.editDiskWheel')}
                    component="diskWheelType"
                    FormComponent={DiskWheelForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="diskWheels"
                      serverPath="disk_wheels"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="diskWheels"
                      serverPath="disk_wheels"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="diskWheels"
                      serverPath="disk_wheels"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="cranks" path="cranks" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listCranks')}
                  serverPath="cranks"
                  clientPath="cranks"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  serverPath="cranks"
                  clientPath="cranks"
                  title={t('componentTypes.titles.newCrank')}
                  component="crankType"
                  FormComponent={CrankForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="cranks"
                    title={t('componentTypes.titles.editCrank')}
                    component="crankType"
                    FormComponent={CrankForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="cranks"
                      serverPath="cranks"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="cranks"
                      serverPath="cranks"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="cranks"
                      serverPath="cranks"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="sprockets" path="sprockets" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listSprockets')}
                  serverPath="sprockets"
                  clientPath="sprockets"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  serverPath="sprockets"
                  clientPath="sprockets"
                  title={t('componentTypes.titles.newSprocket')}
                  component="sprocketType"
                  FormComponent={SprocketForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="sprockets"
                    title={t('componentTypes.titles.editSprocket')}
                    component="sprocketType"
                    FormComponent={SprocketForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="sprockets"
                      serverPath="sprockets"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="sprockets"
                      serverPath="sprockets"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="sprockets"
                      serverPath="sprockets"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="chains" path="chains" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listChains')}
                  serverPath="chains"
                  clientPath="chains"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  serverPath="chains"
                  clientPath="chains"
                  title={t('componentTypes.titles.newChain')}
                  component="chainType"
                  FormComponent={ChainForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="chains"
                    title={t('componentTypes.titles.editChain')}
                    component="chainType"
                    FormComponent={ChainForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="chains"
                      serverPath="chains"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="chains"
                      serverPath="chains"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="chains"
                      serverPath="chains"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="frontDerailleurs" path="frontDerailleurs" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listFrontDerailleurs')}
                  serverPath="front_derailleurs"
                  clientPath="frontDerailleurs"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  serverPath="front_derailleurs"
                  clientPath="frontDerailleurs"
                  title={t('componentTypes.titles.newFrontDerailleur')}
                  component="frontDerailleurType"
                  FormComponent={FrontDerailleurForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="front_derailleurs"
                    title={t('componentTypes.titles.editFrontDerailleur')}
                    component="frontDerailleurType"
                    FormComponent={FrontDerailleurForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="front_derailleurs"
                      serverPath="front_derailleurs"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="front_derailleurs"
                      serverPath="front_derailleurs"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="front_derailleurs"
                      serverPath="front_derailleurs"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>

          <Route id="rearDerailleurs" path="rearDerailleurs" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <ComponentTypeListView
                  title={t('componentTypes.titles.listRearDerailleurs')}
                  serverPath="rear_derailleurs"
                  clientPath="rearDerailleurs"
                />
              )}
            />
            <Route
              path="new"
              element={(
                <ComponentTypeCreateView
                  serverPath="rear_derailleurs"
                  clientPath="rearDerailleurs"
                  title={t('componentTypes.titles.newRearDerailleur')}
                  component="rearDerailleurType"
                  FormComponent={RearDerailleurForm}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <ComponentTypeEditView
                  serverPath="rear_derailleurs"
                  title={t('componentTypes.titles.editRearDerailleur')}
                  component="rearDerailleurType"
                  FormComponent={RearDerailleurForm}
                />
              )}
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                path="/"
                element={(
                  <ComponentTypeEditView
                    serverPath="rear_derailleurs"
                    title={t('componentTypes.titles.editRearDerailleur')}
                    component="rearDerailleurType"
                    FormComponent={RearDerailleurForm}
                  />
                )}
              />
              <Route path="notifications" element={<Outlet />}>
                <Route
                  path="/"
                  element={(
                    <NotificationTypeListView
                      clientPath="rear_derailleurs"
                      serverPath="rear_derailleurs"
                    />
                  )}
                />
                <Route
                  path="new"
                  element={(
                    <NotificationTypeCreateView
                      clientPath="rear_derailleurs"
                      serverPath="rear_derailleurs"
                    />
                  )}
                />
                <Route
                  path=":notificationId"
                  element={(
                    <NotificationTypeEditView
                      clientPath="rear_derailleurs"
                      serverPath="rear_derailleurs"
                    />
                  )}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      ));

      result.push((
        <Route id="chassisType" path="/chassisTypes" element={<Outlet />}>
          <Route path="/" element={<ChassisTypeListView />} />
          <Route path="/:id" element={<ChassisTypeEditView />} />
        </Route>
      ));
    }

    if (currentUser.capabilities.includes('firmware_manager')) {
      result.push((
        <Route key="legacyEpsFirmwares" path="/legacyEpsFirmwares" element={<Outlet />}>
          <Route id="interface" path="interface" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <LegacyEpsFirmwareListView
                  key="interface_firmware_list"
                  component="interface"
                  path="interface_firmwares"
                  title={t('legacyEpsFirmwares.titles.listInterface')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <LegacyEpsFirmwareCreateView
                  component="interface"
                  path="interface_firmwares"
                  title={t('legacyEpsFirmwares.titles.newInterface')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <LegacyEpsFirmwareEditView
                  component="interface"
                  path="interface_firmwares"
                  title={t('legacyEpsFirmwares.titles.editInterface')}
                />
              )}
            />
          </Route>
          <Route id="powerUnit" path="powerUnit" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <LegacyEpsFirmwareListView
                  key="power_unit_firmware_list"
                  component="powerUnit"
                  path="power_unit_firmwares"
                  title={t('legacyEpsFirmwares.titles.listInterface')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <LegacyEpsFirmwareCreateView
                  component="powerUnit"
                  path="power_unit_firmwares"
                  title={t('legacyEpsFirmwares.titles.newInterface')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <LegacyEpsFirmwareEditView
                  component="power_unit"
                  path="power_unit_firmwares"
                  title={t('legacyEpsFirmwares.titles.editInterface')}
                />
              )}
            />
          </Route>
          <Route id="nordic" path="nordic" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <LegacyEpsFirmwareListView
                  key="nordic_application_firmware_list"
                  component="nordic"
                  path="nordic_application_firmwares"
                  title={t('legacyEpsFirmwares.titles.listInterface')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <LegacyEpsFirmwareCreateView
                  component="nordic"
                  path="nordic_application_firmwares"
                  title={t('legacyEpsFirmwares.titles.newInterface')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <LegacyEpsFirmwareEditView
                  component="nordic"
                  path="nordic_application_firmwares"
                  title={t('legacyEpsFirmwares.titles.editInterface')}
                />
              )}
            />
          </Route>
        </Route>
      ));

      result.push((
        <Route key="wirelessEpsFirmwares" path="/wirelessEpsFirmwares" element={<Outlet />}>
          <Route id="backGears" path="backGears" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <WirelessEpsFirmwareListView
                  key="backGear"
                  component="backGears"
                  path="wireless_eps_back_gear_firmwares"
                  title={t('wirelessEpsFirmwares.titles.listBackGear')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <WirelessEpsFirmwareCreateView
                  component="backGears"
                  path="wireless_eps_back_gear_firmwares"
                  title={t('wirelessEpsFirmwares.titles.newBackGear')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <WirelessEpsFirmwareEditView
                  component="backGears"
                  path="wireless_eps_back_gear_firmwares"
                  title={t('wirelessEpsFirmwares.titles.editBackGear')}
                />
              )}
            />
          </Route>

          <Route id="frontGears" path="frontGears" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <WirelessEpsFirmwareListView
                  key="frontGear"
                  component="frontGears"
                  path="wireless_eps_front_gear_firmwares"
                  title={t('wirelessEpsFirmwares.titles.listFrontGear')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <WirelessEpsFirmwareCreateView
                  component="frontGears"
                  path="wireless_eps_front_gear_firmwares"
                  title={t('wirelessEpsFirmwares.titles.newFrontGear')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <WirelessEpsFirmwareEditView
                  component="frontGears"
                  path="wireless_eps_front_gear_firmwares"
                  title={t('wirelessEpsFirmwares.titles.editFrontGear')}
                />
              )}
            />
          </Route>

          <Route id="leftCommands" path="leftCommands" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <WirelessEpsFirmwareListView
                  key="leftCommand"
                  component="leftCommands"
                  path="wireless_eps_left_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.listLeftCommand')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <WirelessEpsFirmwareCreateView
                  component="leftCommands"
                  path="wireless_eps_left_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.newLeftCommand')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <WirelessEpsFirmwareEditView
                  component="leftCommands"
                  path="wireless_eps_left_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.editLeftCommand')}
                />
              )}
            />
          </Route>

          <Route id="rightCommands" path="rightCommands" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <WirelessEpsFirmwareListView
                  key="rightCommands"
                  component="rightCommands"
                  path="wireless_eps_right_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.listRightCommand')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <WirelessEpsFirmwareCreateView
                  component="rightCommands"
                  path="wireless_eps_right_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.newRightCommand')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <WirelessEpsFirmwareEditView
                  component="rightCommands"
                  path="wireless_eps_right_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.editRightCommand')}
                />
              )}
            />
          </Route>

          <Route id="leftCommandRemote1s" path="leftCommandRemote1s" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <WirelessEpsFirmwareListView
                  key="leftCommandRemote1s"
                  component="leftCommandRemote1s"
                  path="wireless_eps_left_command_remote1_firmwares"
                  title={t('wirelessEpsFirmwares.titles.listLeftCommandRemote1')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <WirelessEpsFirmwareCreateView
                  component="leftCommandRemote1s"
                  path="wireless_eps_left_command_remote1_firmwares"
                  title={t('wirelessEpsFirmwares.titles.newLeftCommandRemote1')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <WirelessEpsFirmwareEditView
                  component="leftCommands"
                  path="wireless_eps_left_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.editLeftCommandRemote1')}
                />
              )}
            />
          </Route>

          <Route id="rightCommandRemote1s" path="rightCommandRemote1s" element={<Outlet />}>
            <Route
              path="/"
              element={(
                <WirelessEpsFirmwareListView
                  key="rightCommandRemote1s"
                  component="rightCommandRemote1s"
                  path="wireless_eps_right_command_remote1_firmwares"
                  title={t('wirelessEpsFirmwares.titles.listRightCommandRemote1')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <WirelessEpsFirmwareCreateView
                  component="rightCommandRemote1s"
                  path="wireless_eps_right_command_remote1_firmwares"
                  title={t('wirelessEpsFirmwares.titles.newRightCommandRemote1')}
                />
              )}
            />
            <Route
              path=":id"
              element={(
                <WirelessEpsFirmwareEditView
                  component="rightCommands"
                  path="wireless_eps_right_command_firmwares"
                  title={t('wirelessEpsFirmwares.titles.editRightCommandRemote1')}
                />
              )}
            />
          </Route>

        </Route>
      ));
    }

    if (currentUser.capabilities.includes('user_manager') || currentUser.capabilities.includes('local_user_manager')) {
      result.push((
        <Route id="user" path="/users" element={<Outlet />}>
          <Route path="/" element={<UserListView />} />
          <Route path="/:id" element={<UserEditView />} />
        </Route>
      ));
    }

    return result;
  };

  return (
    <Layout>
      <Header>
        {renderMenu()}
      </Header>
      <Content style={{ padding: 50 }}>
        <Routes>
          {renderRoutes()}
        </Routes>
      </Content>
    </Layout>
  );
};

export default AuthenticatedLayout;
