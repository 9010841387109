/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Alert,
  Space,
} from 'antd';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../commons/components/Loader';
import TranslatableTextInput from '../../commons/components/TranslatableTextInput';
import FirmwareVersionInput from '../../commons/components/FirmwareVersionInput';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

import config from '../../config';
import useDetail from '../../api/hooks/useDetail';

const EditView = (props) => {
  const {
    title,
    path,
  } = props;

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [serverErrors, setServerErrors] = React.useState(null);

  const { currentUser } = React.useContext(AuthenticationContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const fullPath = React.useMemo(() => `v2/epses/${path}`, [path]);

  const {
    load,
    loading,
    data,
    error,
  } = useDetail(fullPath, id);

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (data) {
      reset({
        descriptions: data.descriptions,
        notificationTitles: data.notificationTitles,
        notificationTexts: data.notificationTexts,
        hardwareRevision: data.hardwareRevision,
        racingTeam: data.racingTeam,
        published: data.published,
        version: data.version,
      });
    }
  }, [data]);

  const downloadData = React.useMemo(() => {
    if (data) {
      const url = `data:application/mac-binhex;base64,${data.base64}`;
      const fileName = `${data.version}.hex`;
      return {
        url,
        fileName,
      };
    }
    return null;
  }, [data]);

  const renderLoading = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Loader />
      </Col>
    </Row>
  );

  const renderError = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Alert message={JSON.stringify(error)} type="error" showIcon />
      </Col>
    </Row>
  );

  const renderServerErrors = () => {
    if (serverErrors && serverErrors.length) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <Alert message={serverErrors.join('\n')} type="error" showIcon />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const onSubmit = async (formData) => {
    setServerErrors(null);
    try {
      await axios.put(`${config.baseApiUrl}${fullPath}/${id}`, {
        firmware: formData,
      }, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      navigate(-1);
    }
    catch (e) {
      if (e.isAxiosError && e.response.status === 409) {
        setServerErrors(e.response.data.errors);
      }
      else {
        setServerErrors([t('commons.genericError')]);
      }
    }
  };

  if (loading) {
    return renderLoading();
  }

  if (error) {
    return renderError();
  }

  return (
    <>
      <Row justify="space-between">
        <Col>
          <h1>{title}</h1>
        </Col>
        <Col>
          <Button
            download={downloadData ? downloadData.fileName : null}
            href={downloadData ? downloadData.url : null}
            type="primary"
          >
            {t('commons.actions.download')}
          </Button>
        </Col>
      </Row>
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {renderServerErrors()}
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="descriptions"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.descriptions')}
                    validateStatus={errors != null && errors.descriptions != null ? 'error' : null}
                    help={errors && errors.descriptions && errors.descriptions.message}
                  >
                    <TranslatableTextInput
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="published"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.published')}
                    validateStatus={errors != null && errors.published != null ? 'error' : null}
                    help={errors && errors.published && errors.published.message}
                  >
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="notificationTitles"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.notificationTitles')}
                    validateStatus={errors != null && errors.notificationTitles != null ? 'error' : null}
                    help={errors && errors.notificationTitles && errors.notificationTitles.message}
                  >
                    <TranslatableTextInput
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="notificationTexts"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.notificationTexts')}
                    validateStatus={errors != null && errors.notificationTexts != null ? 'error' : null}
                    help={errors && errors.notificationTexts && errors.notificationTexts.message}
                  >
                    <TranslatableTextInput
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="version"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.version')}
                    validateStatus={errors != null && errors.version != null ? 'error' : null}
                    help={errors && errors.version && errors.version.message}
                  >
                    <FirmwareVersionInput
                      disabled
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
                rules={{
                  required: t('validations.required'),
                }}
              />
            </Col>
            <Col flex={1}>
              <Controller
                name="hardwareRevision"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.hardwareRevision')}
                    validateStatus={errors != null && errors.hardwareRevision != null ? 'error' : null}
                    help={errors && errors.hardwareRevision && errors.hardwareRevision.message}
                  >
                    <FirmwareVersionInput
                      disabled
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex={1}>
              <Controller
                name="racingTeam"
                control={control}
                render={({ field }) => (
                  <Form.Item
                    label={t('legacyEpsFirmwares.form.racingTeam')}
                    validateStatus={errors != null && errors.racingTeam != null ? 'error' : null}
                    help={errors && errors.racingTeam && errors.racingTeam.message}
                  >
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>

          <Row gutter={16} justify="end">
            <Col>
              <Button type="primary" htmlType="submit">
                {t('commons.actions.edit')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  );
};

const propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EditView.propTypes = propTypes;

export default EditView;
