import React from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Row,
  Col,
  Space,
  Input,
  Button,
} from 'antd';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const {
    filter,
    onSubmit,
  } = props;

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
  } = useForm({ defaultValues: filter });

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('chassisTypes.filter.name')}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle" justify="center">
          <Col>
            <Button type="primary" htmlType="submit">
              {t('commons.actions.search')}
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    major_version: PropTypes.number,
    minor_version: PropTypes.number,
    patch_version: PropTypes.number,
    published: PropTypes.bool,
    base_line: PropTypes.bool,
  }).isRequired,
};

Filter.propTypes = propTypes;

export default Filter;
