import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Button,
  Space,
  Alert,
  Table,
} from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Filter from '../components/Filter';

import useList from '../../api/hooks/useList';

const ListView = (props) => {
  const {
    component,
    path,
    title,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const fullPath = React.useMemo(() => `v2/epses/${path}`, [path]);

  const [loadConfig, setLoadConfig] = React.useState({
    pageIndex: 0,
    pageSize: 20,
    filter: {},
  });

  const {
    load,
    loading,
    data,
    error,
  } = useList(fullPath);

  React.useEffect(() => {
    load(loadConfig.pageIndex, loadConfig.pageSize, loadConfig.filter);
  }, [load, loadConfig]);

  const handleFilterChange = React.useCallback((newFilter) => {
    setLoadConfig({
      pageIndex: 0,
      pageSize: loadConfig.pageSize,
      filter: newFilter,
    });
  }, [setLoadConfig, loadConfig]);

  const renderError = () => (
    <Row style={{ height: '100%' }} justify="center" align="middle">
      <Col>
        <Alert message={JSON.stringify(error)} type="error" showIcon />
      </Col>
    </Row>
  );

  const handleTableChange = React.useCallback((page) => {
    setLoadConfig({
      ...loadConfig,
      pageIndex: page.current - 1,
      pageSize: page.pageSize,
    });
  }, [setLoadConfig, loadConfig]);

  if (error) {
    return renderError();
  }

  return (
    <Space size="large" style={{ width: '100%' }} direction="vertical">
      <Row justify="space-between">
        <Col>
          <h1>{title}</h1>
        </Col>
        <Col>
          <Button
            onClick={() => navigate(`/legacyEpsFirmwares/${component}/new`)}
            type="primary"
          >
            {t('commons.actions.addNew')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Filter
            onSubmit={handleFilterChange}
            filter={loadConfig.filter}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Table
            loading={loading}
            dataSource={data ? data.list : []}
            rowKey={(record) => record.id}
            onChange={handleTableChange}
            pagination={{
              current: loadConfig.pageIndex + 1,
              pageSize: loadConfig.pageSize,
              total: data ? data.count : 0,
            }}
          >
            <Table.Column
              title={t('legacyEpsFirmwares.table.version')}
              dataIndex="version"
              key="version"
            />
            <Table.Column
              title={t('legacyEpsFirmwares.table.hardwareRevision')}
              dataIndex="hardwareRevision"
              key="hardwareRevision"
            />
            <Table.Column
              title={t('legacyEpsFirmwares.table.published')}
              dataIndex="published"
              key="published"
              render={(published) => {
                if (published) {
                  return <CheckOutlined />;
                }
                return <CloseOutlined />;
              }}
            />
            <Table.Column
              title={t('legacyEpsFirmwares.table.racingTeam')}
              dataIndex="racingTeam"
              key="racingTeam"
              render={(racingTeam) => {
                if (racingTeam) {
                  return <CheckOutlined />;
                }
                return <CloseOutlined />;
              }}
            />
            <Table.Colum
              title={t('legacyEpsFirmwares.table.actions')}
              key="action"
              render={(text, record) => (
                <Space size="middle">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => navigate(`/legacyEpsFirmwares/${component}/${record.id}`)}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Space>
  );
};

const propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

ListView.propTypes = propTypes;

export default ListView;
