import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
} from 'antd';
import useList from '../../api/hooks/useList';
import Loader from './Loader';

const WirelessEpsFirmwareDependsOnInput = (props) => {
  const {
    disabled,
    path,
    value,
    onChange,
  } = props;

  const fullPath = React.useMemo(() => `v2/wireless_epses/${path}`, [path]);

  const {
    load,
    loading,
    data,
  } = useList(fullPath);

  React.useEffect(() => {
    load(0, 100, { base_line: true });
  }, [load]);

  if (loading) {
    return <Loader />;
  }

  if (data) {
    return (
      <Select
        disabled={disabled}
        onChange={onChange}
        value={value}
        allowClear
      >
        {data.list.map((f) => (
          <Select.Option key={f.id} value={f.id}>{f.version}</Select.Option>
        ))}
      </Select>
    );
  }

  return null;
};

const propTypes = {
  path: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  value: null,
  disabled: false,
};

WirelessEpsFirmwareDependsOnInput.propTypes = propTypes;
WirelessEpsFirmwareDependsOnInput.defaultProps = defaultProps;

export default WirelessEpsFirmwareDependsOnInput;
